
export const WEEK_1_2 = [
  'HTML Document Structure',
  'Linking Pages Together',
 'Images',
  'Headings',
  'Lists',
  'Bold & Italic',
  'Semantic Structural Elements',
  'Navigation',
  'Forms',
  'Different Types of Inputs',
  'CSS intro',
  'CSS Selectors',
  'Box Model',
  'Positioning',
  'Flex Layout',
  'Grid Layout',
]
export const WEEK_3_4 = [
 'SASS intro',
 'Installing Sass / SCSS',
 'Variables in SCSS',
 'Partials',
 'Mixins',
 'Imports',
 'Media Queries',
' Basic Arithmetic',
 'Functions',
 'Markup practice part 1',
 'Markup practice part 2',
 'Markup practice part 3',
' Make our page responsive part 1',
 'Make our page responsive part 1',
]
