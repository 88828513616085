import React from 'react';


const Nav = () => {

    return (
            <nav>
                <h1>Learn how to make HTML pages</h1>
                <h1>Start to build your portfolio</h1>
            </nav>
    )
}

export default Nav;
