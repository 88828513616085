import React from 'react';


const Footer = () => {

    return (
        <footer>
            Happy coding!
        </footer>
    )
}

export default Footer;
